$purple: #7e3e98;

.contatto {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  min-height: 60vh;

  margin: auto;
  padding: 50px 40px 30px 100px;

  &__intro {
    display: flex;

    /* border: 1px solid red; */
    &__intro {
      flex: 0.6;

      h1 {
        font-size: 28px;
        color: $purple;
      }

      p {
        width: 80%;
      }

      .visita {
        margin-top: 40px;
        width: 90%;
        height: 60%;
        position: relative;

        img {
          width: 100%;
          object-fit: contain;
          border: 4px solid #8760b1;
          position: relative;
        }
      }
    }

    &__second {
      flex: 0.4;

      img {
        width: 85%;
        position: relative;
        float: right;
      }
    }
  }

  &__social {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;

    h1 {
      font-size: 26px;
      font-weight: 700;
      margin-top: 0;
      text-align: left;
    }

    &__icons {
      display: flex;
      text-align: center;

      a {
        margin-left: 20px;
        width: 100px;
      }

      a:hover {
        img {
          transform: scale(1.07);
          transition: 0.3s ease-in-out;
        }

        color: red;
      }

      :first-child {
        margin-left: 0;
      }

      img {
        width: 40px;
        display: block;
        margin: auto !important;
        padding-bottom: 4px;
      }
    }
  }

  &__info {
    text-align: center;
    width: 60%;
    margin: 0 auto 0 auto;
    position: relative;
    left: -20px;
  }
}

@media (max-width: 1100px) {
  .contatto {
    padding: 100px 30px 20px 30px;

    &__intro {
      &__intro {
        p {
          margin: auto;
        }

        flex: unset;
        text-align: center;
      }

      &__second {
        display: none;
      }

      .visita {
        margin: 50px auto;
      }
    }

    &__social {
      align-items: center;
    }

    &__info {
      left: 0;
    }
  }
}

@media (max-width: 650px) {
  .contatto {
    &__intro {
      &__intro {
        h1 {
          font-size: 25px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    &__info {
      font-size: 13px;
    }
  }
}

@media (max-width: 550px) {
  .contatto {
    padding-left: 0;
    padding-right: 0;

    &__intro {
      &__intro {
        .visita {
          width: 100%;

          img {
            border-left: none;
            border-right: none;
          }
        }
      }
    }

    &__social {
      &__icons {
        img {
          width: 33px;
        }

        font-size: 14px;
      }
    }

    &__info {
      width: 80%;
    }
  }
}