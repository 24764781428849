.articleCard {
  padding-top: 60px;
  display: flex;
  width: 80%;
  margin: auto;
}
.articleCard__imgContainer {
  height: 200px;
  width: 700px;
}
.articleCard__imgContainer img {
  margin: auto;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.articleCard__row {
  background-color: #7e3e98;
  height: 200px;
  width: 12px;
  margin: 0 15px;
}
.articleCard__text h3 {
  color: #7e3e98;
  font-size: 20px;
  margin: 0;
  margin-bottom: 15px;
  margin-top: 5px;
}
.articleCard__text h3 span {
  font-size: 13px;
  color: #000;
  font-weight: 500;
  float: right;
  margin-right: 25px;
  margin-top: 4px;
}
.articleCard__text p {
  margin: 0;
}
.articleCard__text a {
  text-decoration: none;
  color: white;
  background: #7e3e98;
  float: right;
  margin-right: 15px;
  padding: 10px 30px;
  margin-top: 40px;
}/*# sourceMappingURL=articleCard.css.map */