$purple: #7e3e98;

header {
  height: 100px;
  max-width: 1600px;
  margin: auto;
  display: flex;
  align-items: center;
  border-bottom: 3px solid $purple;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;

  .logo {
    height: 100%;
    width: 140px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  .menu {
    flex: 1;
    display: flex;
    align-items: center;

    &__item {
      margin-left: 7vw;
      text-decoration: none;
      outline: none;
      font-size: 18px;
      font-weight: 500;
      color: black;
    }
  }

  .cta {
    width: 15vw;
    max-width: 200px;
    min-width: 100px;
    padding: 12px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    background-color: $purple;
    margin-right: 50px;
    font-weight: 400;
  }
}

@media (max-width: 1000px) {
  header {

    .cta,
    .menu__item {
      font-size: 15px;
    }
  }
}

@media (max-width: 600px) {
  header {
    height: 80px;

    .logo {
      width: 80px;

      img {
        width: 80%;
      }
    }

    .cta {
      margin-right: 15px;
    }
  }
}

@media (max-width: 480px) {
  header {
    .logo {
      display: none;
    }

    .cta {
      padding: 12px 15px;
    }
  }
}