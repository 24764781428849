$purple: #7e3e98;

.articolo {
  max-width: 1600px;
  margin: auto;

  &__intro {
    position: relative;

    img {
      width: 100%;
      height: 500px;
      object-fit: cover;
      filter: brightness(40%);
    }

    h1 {
      text-align: center;
      position: absolute;
      color: white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-transform: uppercase;
      margin: auto;
      font-size: 30px;
    }
  }

  &__text {
    margin-left: 100px;
    margin-top: 100px;
    margin-bottom: 80px;
    width: 90%;

    .imgCont {
      border-radius: 50%;
      width: 300px;
      height: 300px;
      overflow: hidden;
      float: left;
      shape-outside: circle();
      margin: 20px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    p {
      margin: 0;
      margin-bottom: 15px;
      padding-right: 50px;
    }
  }

  &__button {
    display: inline-block;
    padding: 10px 80px;
    background: $purple;
    color: white;
    text-decoration: none;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
  }

  .content {
    display: flex;

    .sidebar {
      width: 300px !important;
      margin-top: 150px;

      h3 {
        text-align: center;
        color: $purple;
        border-bottom: 2px solid $purple;
      }

      ul {
        text-align: center;
        padding: 0;

        li {
          border-bottom: 1px solid grey;
          padding-bottom: 10px;

          a {
            padding: 10px;
            color: rgb(59, 59, 59);
          }

          a:hover {
            transition: 0.5s ease;
            color: tomato;
          }

          margin-top: 10px;
          list-style-type: none;
        }
      }
    }
  }
}

@media (min-width: 1600px) {
  .articolo {
    &__text {
      width: 65%;
    }

    .content {
      margin: auto;
    }
  }
}

@media (max-width: 1100px) {
  .articolo {
    position: relative;

    .sidebar {
      display: none;
    }

    &__text {
      width: 100%;
      margin: 100px;
      margin-top: 45px;
      text-align: center;

      p {
        margin: auto;
        padding: 0;
        margin-bottom: 15px;
      }

      a {
        position: absolute;
      }
    }
  }
}

@media (max-width: 750px) {
  .articolo {
    &__intro {
      h1 {
        font-size: 22px;
      }

      img {
        height: 350px;
        filter: brightness(50%);
      }
    }

    &__text {
      margin: 80px 30px;


      text-align: center;

      .imgCont {
        display: none;
      }
    }

    &__button {
      width: 80%;
    }
  }
}

@media (max-width: 600px) {
  .articolo {
    &__intro {
      h1 {
        font-size: 18px;
        width: 100%;
        padding: 0 30px;
      }
    }

    &__text {
      margin: 40px 30px 70px 30px;
    }
  }
}