.esperto .grid {
  padding-top: 20px;
  margin: auto;
  max-width: 1600px;
  display: grid;
  grid-template-areas: "elem1 elem2 elem3" "elem4 elem5 elem6" "elem7 cta cta";
  grid-gap: 15px 0px;
  padding-bottom: 40px;
  border-bottom: 3px solid #7e3e98;
}
.esperto .grid__cta {
  position: relative;
  text-align: center;
  margin: auto;
  width: 55%;
  max-width: 805px;
  bottom: 270px;
  right: -210px;
  margin-bottom: -200px;
}
.esperto .grid__cta p {
  margin-bottom: 35px;
  font-size: 14px;
}
.esperto .grid__cta .contatti {
  text-decoration: none;
  background-color: #7e3e98;
  color: white;
  padding: 10px 30px;
}
.esperto .grid__cta .preventivo {
  margin: auto;
  width: 70%;
  margin-top: 30px;
  color: rebeccapurple;
  font-size: 13px;
}
.esperto .grid__cta .preventivoFile {
  color: rebeccapurple;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 1250px) {
  .esperto {
    margin-bottom: 60px;
  }
  .esperto .grid {
    border: none;
    grid-template-areas: "elem1 elem2" "elem3 elem4" "elem5 elem6" "elem7 null" "cta cta";
  }
  .esperto .grid__cta {
    position: unset;
  }
}
@media (max-width: 850px) {
  .esperto .grid {
    grid-template-areas: "elem1" "elem2" "elem3" "elem4" "elem5" "elem6" "elem7" "cta";
  }
  .esperto .grid .espertoCard {
    max-width: 450px;
    width: 90%;
  }
  .esperto .grid .espertoCard .text p {
    margin-top: -15px;
    padding: 0 15px;
  }
  .esperto .grid .espertoCard .text h3 {
    margin: 25px auto 20px auto;
    width: 90%;
    padding-bottom: 15px;
  }
  .esperto .grid .opacity {
    width: 100%;
  }
  .esperto .grid__cta p {
    font-size: 12px;
  }
  .esperto .grid__cta a {
    font-size: 14px;
  }
  .esperto .grid__cta .preventivo {
    font-size: 10px;
    width: 100%;
  }
  .esperto .grid__cta .preventivo a {
    font-size: 12px;
  }
}
@media (max-width: 550px) {
  .esperto .grid__cta {
    width: 70%;
  }
}
@media (max-width: 400px) {
  .espertoCard .text .different {
    font-size: 13px;
  }
}/*# sourceMappingURL=esperto.css.map */