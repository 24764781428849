.footer {
  background: #1f1c1c;
  margin-top: 50px;
}
.footer__container {
  max-width: 1600px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  padding-bottom: 25px;
}
.footer__container .contatti {
  margin-left: 60px;
}
.footer__container .contatti h3 {
  text-align: center;
  padding-top: 40px;
  color: #7e3e98;
  margin-bottom: 30px;
}
.footer__container .contatti .item {
  display: flex;
  width: 270px;
  margin: auto;
}
.footer__container .contatti .item a,
.footer__container .contatti .item p {
  color: white;
  font-weight: 200;
}
.footer__container .contatti .item img {
  width: 24px;
  margin-right: 15px;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.footer__container .row {
  height: 320px;
  width: 5px;
  background-color: #7e3e98;
  position: relative;
  top: 50%;
  transform: translateY(5%);
}
.footer__container .mappa {
  width: 40%;
  display: flex;
  margin-top: 25px;
}

@media (max-width: 1000px) {
  .footer__container {
    flex-direction: column;
  }
  .footer__container .contatti {
    margin-left: 0;
  }
  .footer__container .contatti .item {
    align-items: center;
    justify-content: center;
  }
  .footer__container .row {
    height: 3px;
    width: 80vw;
    margin: auto;
    margin-top: 30px;
  }
  .footer__container .mappa {
    width: 90vw;
    margin: 40px auto;
    height: 400px;
  }
}
@media (max-width: 360px) {
  .footer__container .item:last-child img {
    display: none;
  }
}/*# sourceMappingURL=footer.css.map */