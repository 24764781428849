.home {
  border: none;
}

@media (min-width: 1250px) {
  .home {
    margin-top: 0;
  }
}
@media (max-width: 800px) {
  .home {
    margin-top: 70px;
  }
}/*# sourceMappingURL=home.css.map */