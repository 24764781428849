.chiSono__intro {
  max-width: 1600px;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}
.chiSono__intro__text {
  margin-top: 20px;
  width: 40%;
  padding-right: 60px;
}
.chiSono__intro__text h3 {
  color: #7e3e98;
  font-size: 24px;
}
.chiSono__intro__text p {
  margin: 0;
  max-width: 450px;
  padding-right: 30px;
  line-height: 1.8;
  /* span {
    color: $purple;
    font-weight: 600;
    font-size: 17px;
  } */
}
.chiSono__intro img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 400px;
}

@media (max-width: 1250px) {
  .chiSono {
    border-top: 3px solid #7e3e98;
    margin-top: 250px;
    padding-top: 0;
  }
}
@media (max-width: 945px) {
  .chiSono__intro {
    flex-direction: column-reverse;
    margin-top: 50px;
  }
  .chiSono__intro img {
    -o-object-fit: contain;
       object-fit: contain;
    width: 40%;
    margin: auto;
  }
  .chiSono__intro__text {
    width: 90%;
    margin: auto;
    text-align: center;
    padding-right: 0;
  }
  .chiSono__intro__text h3 {
    font-size: 20px;
  }
  .chiSono__intro__text h3 br {
    display: none;
  }
  .chiSono__intro__text p {
    font-size: 12px;
    padding-right: 0;
    width: 80%;
    margin: auto;
  }
}
@media (max-width: 700px) {
  .chiSono__intro img {
    width: 55%;
  }
}/*# sourceMappingURL=chiSono.css.map */