$purple: #7e3e98;

.intro {
  display: flex;
  border-bottom: 3px solid $purple;
  padding-bottom: 40px;
  max-width: 1600px;
  margin: auto;

  &__text {
    flex: 0.4;
    padding: 40px 25px 0 30px;

    h1 {
      color: $purple;
      font-size: 26px;
      line-height: 1.4;

      span {
        font-size: 32px;
      }
    }

    p {
      line-height: 1.6;
      margin-right: 60px;
    }
  }

  .carousel {
    flex: 0.6;
    position: relative;

    .intro__carousel {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: contain;
      top: 0;
    }

    .dot {
      position: absolute;
      display: flex;
      left: 50%;
      transform: translateX(-50%);
      bottom: 15px;

      span {
        display: block;
        width: 10px;
        height: 10px;
        background: #a0a0a08c;
        margin-right: 5px;
        border-radius: 50%;
      }

      .active {
        background: white;
      }
    }
  }
}

@media (max-width: 1263px) {
  .intro {
    .carousel {
      .dot {
        display: none;
      }
    }
  }
}

@media (max-width: 950px) {
  .intro {
    padding-bottom: 0;
    border-bottom: none;
    display: block;
    position: relative;

    &__text {
      position: absolute;
      top: 38%;
      transform: translateY(-50%);
      z-index: 2;
      text-align: center;

      p {
        margin: 0;
        padding: 0 30px;
        color: rgb(219, 215, 215);
      }

      h1 {
        color: white;
      }
    }

    .carousel {
      .intro__carousel {
        filter: brightness(50%);
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 800px) {
  .intro {
    .hide {
      display: none;
    }

    h1 {
      span {
        font-size: 30px;
      }

      font-size: 20px;
      margin-bottom: 25px;
    }

    p {
      font-size: 14px;
    }
  }
}

@media (max-width: 650px) {
  .intro {
    &__text {
      padding: 0;
      margin-top: 10%;

      h1 {
        margin: 0;
        width: 100vw;

      }

      p {
        display: none;
      }
    }
  }
}

@media (max-width: 430px) {
  .intro {
    &__text {
      h1 {
        span {
          font-size: 24px;
        }

        font-size: 18px;
      }
    }
  }
}