.formazione {
  max-width: 1600px;
}
.formazione h2 {
  margin-bottom: 40px;
}
.formazione__table {
  max-width: 1350px;
  width: 80%;
  margin: auto;
  border-collapse: collapse;
}
.formazione__table tr :first-child {
  width: 20%;
  padding-left: 15px;
}
.formazione__table tr :nth-child(2) {
  width: 80%;
}
.formazione__table .mobile {
  display: none;
}
.formazione__table__title {
  color: #7e3e98;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 2px solid #7e3e98;
}
.formazione__table .formazione__item {
  border-bottom: 2px solid #cec8d0;
}
.formazione__table .formazione__item :last-child {
  padding: 10px 0;
}
.formazione__tableMobile {
  display: none;
}

button {
  border: none;
  color: white;
  font-weight: 600;
  background: none;
  color: #7e3e98;
  text-decoration: underline;
  margin-top: 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 40px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .formazione__table {
    display: none;
  }
  .formazione__tableMobile {
    display: block;
    width: 85%;
    margin: auto;
  }
  .formazione__tableMobile tbody .formazione__item {
    display: none;
  }
  .formazione__tableMobile tbody .mobile td {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 2px solid #cec8d0;
  }
  .formazione__tableMobile tbody .mobile td .date {
    width: 100%;
    margin-right: 20px;
    color: #7e3e98;
    font-weight: 600;
    font-size: 18px;
  }
  .formazione__tableMobile tbody .mobile td .title {
    font-size: 15px;
  }
  button {
    margin-top: 0px;
  }
}
@media (max-width: 600px) {
  .formazione__tableMobile {
    width: 90%;
  }
  .formazione__tableMobile .date {
    font-size: 14px !important;
  }
  .formazione__tableMobile p.title {
    font-size: 13px !important;
    margin: 0;
  }
}/*# sourceMappingURL=formazione.css.map */