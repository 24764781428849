$purple: #7e3e98;

.espertoCard {
  width: 400px;
  height: 250px;
  position: relative;
  margin: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .opacity {
    position: absolute;
    z-index: 1;
    background: #00000096;
    height: 250px;
    width: 400px;
    top: 0;
  }
  .text {
    text-align: center;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    h3 {
      font-weight: 700;
      font-size: 20px;
      color: #d5a444;
      margin-top: 25px;
    }
    p {
      margin-top: 30px;
      color: white;
      font-weight: 200;
      line-height: 2;
      padding: 0 20px;
      font-size: 15px;
      width: 100%;
    }
    .different {
      line-height: 1.8;
    }
  }
}
