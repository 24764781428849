.articolo {
  max-width: 1600px;
  margin: auto;
}
.articolo__intro {
  position: relative;
}
.articolo__intro img {
  width: 100%;
  height: 500px;
  -o-object-fit: cover;
     object-fit: cover;
  filter: brightness(40%);
}
.articolo__intro h1 {
  text-align: center;
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  margin: auto;
  font-size: 30px;
}
.articolo__text {
  margin-left: 100px;
  margin-top: 100px;
  margin-bottom: 80px;
  width: 90%;
}
.articolo__text .imgCont {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  overflow: hidden;
  float: left;
  shape-outside: circle();
  margin: 20px;
}
.articolo__text .imgCont img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.articolo__text p {
  margin: 0;
  margin-bottom: 15px;
  padding-right: 50px;
}
.articolo__button {
  display: inline-block;
  padding: 10px 80px;
  background: #7e3e98;
  color: white;
  text-decoration: none;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
}
.articolo .content {
  display: flex;
}
.articolo .content .sidebar {
  width: 300px !important;
  margin-top: 150px;
}
.articolo .content .sidebar h3 {
  text-align: center;
  color: #7e3e98;
  border-bottom: 2px solid #7e3e98;
}
.articolo .content .sidebar ul {
  text-align: center;
  padding: 0;
}
.articolo .content .sidebar ul li {
  border-bottom: 1px solid grey;
  padding-bottom: 10px;
  margin-top: 10px;
  list-style-type: none;
}
.articolo .content .sidebar ul li a {
  padding: 10px;
  color: rgb(59, 59, 59);
}
.articolo .content .sidebar ul li a:hover {
  transition: 0.5s ease;
  color: tomato;
}

@media (min-width: 1600px) {
  .articolo__text {
    width: 65%;
  }
  .articolo .content {
    margin: auto;
  }
}
@media (max-width: 1100px) {
  .articolo {
    position: relative;
  }
  .articolo .sidebar {
    display: none;
  }
  .articolo__text {
    width: 100%;
    margin: 100px;
    margin-top: 45px;
    text-align: center;
  }
  .articolo__text p {
    margin: auto;
    padding: 0;
    margin-bottom: 15px;
  }
  .articolo__text a {
    position: absolute;
  }
}
@media (max-width: 750px) {
  .articolo__intro h1 {
    font-size: 22px;
  }
  .articolo__intro img {
    height: 350px;
    filter: brightness(50%);
  }
  .articolo__text {
    margin: 80px 30px;
    text-align: center;
  }
  .articolo__text .imgCont {
    display: none;
  }
  .articolo__button {
    width: 80%;
  }
}
@media (max-width: 600px) {
  .articolo__intro h1 {
    font-size: 18px;
    width: 100%;
    padding: 0 30px;
  }
  .articolo__text {
    margin: 40px 30px 70px 30px;
  }
}/*# sourceMappingURL=article.css.map */