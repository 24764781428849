.contatto {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  min-height: 60vh;
  margin: auto;
  padding: 50px 40px 30px 100px;
}
.contatto__intro {
  display: flex;
  /* border: 1px solid red; */
}
.contatto__intro__intro {
  flex: 0.6;
}
.contatto__intro__intro h1 {
  font-size: 28px;
  color: #7e3e98;
}
.contatto__intro__intro p {
  width: 80%;
}
.contatto__intro__intro .visita {
  margin-top: 40px;
  width: 90%;
  height: 60%;
  position: relative;
}
.contatto__intro__intro .visita img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border: 4px solid #8760b1;
  position: relative;
}
.contatto__intro__second {
  flex: 0.4;
}
.contatto__intro__second img {
  width: 85%;
  position: relative;
  float: right;
}
.contatto__social {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}
.contatto__social h1 {
  font-size: 26px;
  font-weight: 700;
  margin-top: 0;
  text-align: left;
}
.contatto__social__icons {
  display: flex;
  text-align: center;
}
.contatto__social__icons a {
  margin-left: 20px;
  width: 100px;
}
.contatto__social__icons a:hover {
  color: red;
}
.contatto__social__icons a:hover img {
  transform: scale(1.07);
  transition: 0.3s ease-in-out;
}
.contatto__social__icons :first-child {
  margin-left: 0;
}
.contatto__social__icons img {
  width: 40px;
  display: block;
  margin: auto !important;
  padding-bottom: 4px;
}
.contatto__info {
  text-align: center;
  width: 60%;
  margin: 0 auto 0 auto;
  position: relative;
  left: -20px;
}

@media (max-width: 1100px) {
  .contatto {
    padding: 100px 30px 20px 30px;
  }
  .contatto__intro__intro {
    flex: unset;
    text-align: center;
  }
  .contatto__intro__intro p {
    margin: auto;
  }
  .contatto__intro__second {
    display: none;
  }
  .contatto__intro .visita {
    margin: 50px auto;
  }
  .contatto__social {
    align-items: center;
  }
  .contatto__info {
    left: 0;
  }
}
@media (max-width: 650px) {
  .contatto__intro__intro h1 {
    font-size: 25px;
  }
  .contatto__intro__intro p {
    font-size: 14px;
  }
  .contatto__info {
    font-size: 13px;
  }
}
@media (max-width: 550px) {
  .contatto {
    padding-left: 0;
    padding-right: 0;
  }
  .contatto__intro__intro .visita {
    width: 100%;
  }
  .contatto__intro__intro .visita img {
    border-left: none;
    border-right: none;
  }
  .contatto__social__icons {
    font-size: 14px;
  }
  .contatto__social__icons img {
    width: 33px;
  }
  .contatto__info {
    width: 80%;
  }
}/*# sourceMappingURL=contatti.css.map */