header {
  height: 100px;
  max-width: 1600px;
  margin: auto;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #7e3e98;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;
}
header .logo {
  height: 100%;
  width: 140px;
}
header .logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: left;
     object-position: left;
}
header .menu {
  flex: 1;
  display: flex;
  align-items: center;
}
header .menu__item {
  margin-left: 7vw;
  text-decoration: none;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  color: black;
}
header .cta {
  width: 15vw;
  max-width: 200px;
  min-width: 100px;
  padding: 12px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  background-color: #7e3e98;
  margin-right: 50px;
  font-weight: 400;
}

@media (max-width: 1000px) {
  header .cta,
  header .menu__item {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  header {
    height: 80px;
  }
  header .logo {
    width: 80px;
  }
  header .logo img {
    width: 80%;
  }
  header .cta {
    margin-right: 15px;
  }
}
@media (max-width: 480px) {
  header .logo {
    display: none;
  }
  header .cta {
    padding: 12px 15px;
  }
}/*# sourceMappingURL=header.css.map */