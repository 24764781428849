*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
}

h2 {
  margin-top: 70px;
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  color: #7e3e98;
}

p {
  font-weight: 400;
}

@media (max-width: 800px) {
  h2 {
    font-size: 20px;
  }
}