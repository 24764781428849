$purple: #7e3e98;

.footer {
  background: #1f1c1c;
  margin-top: 50px;

  &__container {
    max-width: 1600px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    padding-bottom: 25px;

    .contatti {
      margin-left: 60px;

      h3 {
        text-align: center;
        padding-top: 40px;
        color: $purple;
        margin-bottom: 30px;
      }

      .item {
        display: flex;

        width: 270px;
        margin: auto;

        a,
        p {
          color: white;
          font-weight: 200;
        }

        img {
          width: 24px;
          margin-right: 15px;
          object-fit: contain;
          display: block;
        }
      }
    }

    .row {
      height: 320px;
      width: 5px;
      background-color: $purple;
      position: relative;
      top: 50%;
      transform: translateY(5%);
    }

    .mappa {
      width: 40%;
      display: flex;
      margin-top: 25px;
    }
  }
}

@media (max-width: 1000px) {
  .footer {
    &__container {
      flex-direction: column;

      .contatti {
        margin-left: 0;

        .item {
          align-items: center;
          justify-content: center;
        }
      }

      .row {
        height: 3px;
        width: 80vw;
        margin: auto;
        margin-top: 30px;
      }

      .mappa {
        width: 90vw;
        margin: 40px auto;
        height: 400px;
      }
    }
  }
}

@media (max-width: 360px) {
  .footer {
    &__container {
      .item:last-child {
        img {
          display: none;
        }
      }
    }
  }
}