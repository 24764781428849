$purple: #7e3e98;

.blog {
  border-top: 3px solid $purple;
  max-width: 1600px;
  margin: auto;
  padding-bottom: 40px;
  padding-top: 160px;
  border-bottom: 3px solid $purple;
  :first-child div {
    margin-top: 20px;
  }
  .link {
    background-color: $purple;
    color: white;
    display: block;
    padding: 10px;
    text-align: center;
    width: 250px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 50px;
    text-decoration: none;
  }
}

@media (max-width: 1250px) {
  .blog {
    padding-top: 0;
  }
}

@media (max-width: 1000px) {
  .blog {
    a {
      font-size: 14px;
    }
    :first-child div {
      margin-top: -10px;
    }
    .articleCard {
      &__imgContainer {
        display: none;
      }
      &__row {
        display: none;
      }
      &__text {
        a {
          max-width: 150px;
          min-width: 100px;
          width: 30%;
          text-align: center;
          margin-top: 25px;
          font-size: 13px;
        }
        p {
          font-size: 13px;
        }
      }
    }
  }
}
