$purple: #7e3e98;

.chiSono {
  &__intro {
    max-width: 1600px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;

    &__text {
      margin-top: 20px;
      width: 40%;
      padding-right: 60px;

      h3 {
        color: $purple;
        font-size: 24px;
      }

      p {
        margin: 0;
        max-width: 450px;
        padding-right: 30px;
        line-height: 1.8;
        /* span {
          color: $purple;
          font-weight: 600;
          font-size: 17px;
        } */
      }
    }

    img {
      object-fit: cover;
      width: 400px;
    }
  }
}

@media (max-width: 1250px) {
  .chiSono {
    border-top: 3px solid $purple;
    margin-top: 250px;
    padding-top: 0;
  }
}

@media (max-width: 945px) {
  .chiSono {
    &__intro {
      flex-direction: column-reverse;
      margin-top: 50px;

      img {
        object-fit: contain;
        width: 40%;
        margin: auto;
      }

      &__text {
        width: 90%;
        margin: auto;
        text-align: center;
        padding-right: 0;

        h3 {
          font-size: 20px;

          br {
            display: none;
          }
        }

        p {
          font-size: 12px;
          padding-right: 0;
          width: 80%;
          margin: auto;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .chiSono {
    &__intro {
      img {
        width: 55%;
      }
    }
  }
}