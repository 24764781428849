.espertoCard {
  width: 400px;
  height: 250px;
  position: relative;
  margin: auto;
}
.espertoCard img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.espertoCard .opacity {
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5882352941);
  height: 250px;
  width: 400px;
  top: 0;
}
.espertoCard .text {
  text-align: center;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.espertoCard .text h3 {
  font-weight: 700;
  font-size: 20px;
  color: #d5a444;
  margin-top: 25px;
}
.espertoCard .text p {
  margin-top: 30px;
  color: white;
  font-weight: 200;
  line-height: 2;
  padding: 0 20px;
  font-size: 15px;
  width: 100%;
}
.espertoCard .text .different {
  line-height: 1.8;
}/*# sourceMappingURL=espertoCard.css.map */