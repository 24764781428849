$purple: #7e3e98;

.formazione {
  max-width: 1600px;

  h2 {
    margin-bottom: 40px;
  }

  &__table {
    max-width: 1350px;
    width: 80%;
    margin: auto;
    border-collapse: collapse;

    tr {
      :first-child {
        width: 20%;
        padding-left: 15px;
      }

      :nth-child(2) {
        width: 80%;
      }
    }

    .mobile {
      display: none;
    }

    &__title {
      color: $purple;
      font-weight: 600;
      font-size: 18px;
      border-bottom: 2px solid $purple;
    }

    .formazione__item {
      border-bottom: 2px solid #cec8d0;

      :last-child {
        padding: 10px 0;
      }
    }
  }

  &__tableMobile {
    display: none;
  }
}

button {
  border: none;
  color: white;
  font-weight: 600;
  background: none;
  color: $purple;
  text-decoration: underline;
  margin-top: 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 40px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .formazione {
    &__table {
      display: none;
    }

    &__tableMobile {
      display: block;
      width: 85%;
      margin: auto;

      tbody {
        .formazione__item {
          display: none;
        }

        .mobile {
          td {
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-bottom: 2px solid #cec8d0;

            .date {
              width: 100%;
              margin-right: 20px;
              color: $purple;
              font-weight: 600;
              font-size: 18px;
            }

            .title {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  button {
    margin-top: 0px;
  }
}

@media (max-width: 600px) {
  .formazione {
    &__tableMobile {
      width: 90%;

      .date {
        font-size: 14px !important;
      }

      p.title {
        font-size: 13px !important;
        margin: 0;
      }
    }
  }
}