.intro {
  display: flex;
  border-bottom: 3px solid #7e3e98;
  padding-bottom: 40px;
  max-width: 1600px;
  margin: auto;
}
.intro__text {
  flex: 0.4;
  padding: 40px 25px 0 30px;
}
.intro__text h1 {
  color: #7e3e98;
  font-size: 26px;
  line-height: 1.4;
}
.intro__text h1 span {
  font-size: 32px;
}
.intro__text p {
  line-height: 1.6;
  margin-right: 60px;
}
.intro .carousel {
  flex: 0.6;
  position: relative;
}
.intro .carousel .intro__carousel {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  top: 0;
}
.intro .carousel .dot {
  position: absolute;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
}
.intro .carousel .dot span {
  display: block;
  width: 10px;
  height: 10px;
  background: rgba(160, 160, 160, 0.5490196078);
  margin-right: 5px;
  border-radius: 50%;
}
.intro .carousel .dot .active {
  background: white;
}

@media (max-width: 1263px) {
  .intro .carousel .dot {
    display: none;
  }
}
@media (max-width: 950px) {
  .intro {
    padding-bottom: 0;
    border-bottom: none;
    display: block;
    position: relative;
  }
  .intro__text {
    position: absolute;
    top: 38%;
    transform: translateY(-50%);
    z-index: 2;
    text-align: center;
  }
  .intro__text p {
    margin: 0;
    padding: 0 30px;
    color: rgb(219, 215, 215);
  }
  .intro__text h1 {
    color: white;
  }
  .intro .carousel .intro__carousel {
    filter: brightness(50%);
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (max-width: 800px) {
  .intro .hide {
    display: none;
  }
  .intro h1 {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .intro h1 span {
    font-size: 30px;
  }
  .intro p {
    font-size: 14px;
  }
}
@media (max-width: 650px) {
  .intro__text {
    padding: 0;
    margin-top: 10%;
  }
  .intro__text h1 {
    margin: 0;
    width: 100vw;
  }
  .intro__text p {
    display: none;
  }
}
@media (max-width: 430px) {
  .intro__text h1 {
    font-size: 18px;
  }
  .intro__text h1 span {
    font-size: 24px;
  }
}/*# sourceMappingURL=intro.css.map */